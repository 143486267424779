











































































import {
	Component,
	Vue
} from "vue-property-decorator"
import {
	PageMode
} from '@/utils/type'
import {
	apiMpWeChatReplyAdd,
	apiMpWeChatReplyEdit,
	apiMpWeChatReplyDetail
} from "@/api/channel/mp_wechat"
@Component({
	components: {},
})
export default class replyEdit extends Vue {

	/** S Data **/
	mode: string = PageMode['ADD'] // 当前页面【add: 添加用户等级 | edit: 编辑用户等级】
	//replyType = 0  // 回复类型 1-关注回复 2-关键词回复 3-默认回复
	identity: number | null = null // 当前编辑回复ID  valid: mode = 'edit'
	form = {
		reply_type: 1, // 回复类型 1-关注回复 2-关键词回复 3-默认回复
		name: '', // 规格名称
		content_type: 1, // 内容类型 1-文本
		content: '', // 内容
		status: 1, // 启用状态 0-禁用 1-开启
		keyword: '', // 关键词 *关键词回复必填
		matching_type: 1, // 匹配方式 1-全匹配 2-模糊匹配 *关键词回复必填
		sort: '', // 排序值 *关键词回复必填 
		reply_num: 1, // 回复数量 *关键词回复必填 1-回复匹配首条
	}

	formRules = {
		name: [{
			required: true,
			message: '请输入规则名称',
			trigger: 'blur'
		}],
		keyword: [{
			required: true,
			message: '请输入关键词',
			trigger: 'blur'
		}],
		sort: [{
				required: true,
				message: '请输入排序值',
				trigger: 'blur'
			},
			{
				type: 'number',
				min: 1,
				message: '请输入大于0的数字值',
				trigger: 'blur'
			}
		],
		matching_type: [{
			required: true,
			message: '请选择匹配方式',
			trigger: 'change'
		}],
		content_type: [{
			required: true,
			message: '请选择内容类型',
			trigger: 'change'
		}],
		content: [{
			required: true,
			message: '请输入回复内容',
			trigger: 'blur'
		}],
	}
	$refs!: {
		formRef: any
	}
	/** E Data **/

	/** S Methods **/
	// 表单提交
	onSubmit() {
		// 验证表单格式是否正确
		this.$refs.formRef.validate((valid: boolean): any => {
			if (!valid) return;

			// 请求发送
			switch (this.mode) {
				case PageMode['ADD']:
					return this.handleMpWeChatReplyAdd()
				case PageMode['EDIT']:
					return this.handleMpWeChatReplyEdit()
			}
		})
	}

	// 新增
	handleMpWeChatReplyAdd() {
		apiMpWeChatReplyAdd(this.form).then(() => {
			//this.$message.success('添加成功!')
			setTimeout(() => this.$router.go(-1), 500)
		}).catch(() => {
			//this.$message.error('保存失败!')
		})
	}

	// 编辑
	handleMpWeChatReplyEdit() {
		const params = this.form
		const id: number = this.identity as number
		apiMpWeChatReplyEdit({
				...params,
				id
			})
			.then(() => {
				//this.$message.success('保存成功!')
				setTimeout(() => this.$router.go(-1), 500)
				//this.initMpWeChatReplyDetail()
			})
			.catch(() => {
				// this.$message.error('保存失败!')
			})
	}
	// 表单初始化数据 [编辑模式] mode => edit
	initMpWeChatReplyDetail() {
		apiMpWeChatReplyDetail({
			id: this.identity as number
		}).then((res: any) => {
			Object.keys(res).map(key => {
				this.$set(this.form, key, res[key])
			})
		}).catch(() => {
			// this.$message.error('数据初始化失败，请刷新重载！')
		})
	}
	/** E Methods **/

	/** S Life Cycle **/
	created() {
		const query: any = this.$route.query

		if (query.mode) this.mode = query.mode
		if (query.replyType) this.form.reply_type = query.replyType * 1
		// 编辑模式：初始化数据
		if (this.mode === PageMode['EDIT']) {
			this.identity = query.id
			this.initMpWeChatReplyDetail()
		}
	}

	/** E Life Cycle **/
}
